<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 支付成功
 * @Date: 2020-11-08 20:06:42
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-09 11:00:06
 * @FilePath: /yue_quanzhan_h5_own/src/views/localLife/comeOn/paymentok.vue
-->
<template>
  <div class="content">
    <img src="~@/assets/images/dui2.png" alt="OK">
    <div class="ok_1">已支付</div>
    <div class="ok_2">¥184.91</div>
    <img src="~@/assets/images/1168.png" alt="推荐">
    <div class="ok_3">感谢您信任团油，欢迎您再次消费</div>
    <div class="ok_title">加油支付明细</div>
    <ul class="order">
      <li><span>交易单号</span><span>HL0000020492006291IMD01</span></li>
      <li><span>下单时间</span><span>2020-06-29 08:56:43</span></li>
      <li><span>油站名称</span><span>北京高桥加油站</span></li>
      <li><span>地址</span><span>北京市朝阳区高碑店</span></li>
      <li><span>油号</span><span>92#</span></li>
      <li><span>枪号</span><span>3号枪</span></li>
      <van-row class="order_l">
        <van-col :span="4">加油金额</van-col>
        <van-col :span="12">实际升数以油站加油机为准</van-col>
        <van-col :span="8">30.36升</van-col>
      </van-row>
    </ul>
    <ul class="order">
      <li><span>直降优惠</span><span style="color:#F7263C;">- ¥15.09</span></li>
      <li><span>奖励</span><span style="color:#F7263C;">- ¥0</span></li>
    </ul>
    <ul class="order">
      <li><span style="font-size:19px;">加油支付</span><span
        style="font-size:19px;color:#333333;font-weight: 500;"
      >¥ 184.91</span></li>
    </ul>
    <ul class="order_b">
      <li><span>微信支付</span><span>¥ 184.91</span></li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Row } from 'vant'

Vue.use(Col).use(Row)
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.content {
  padding: 10px;
  background-color: #f8f8f8;
  min-height: 100vh;

  & > img:nth-child(1) {
    width: 62px;
    margin-top: 20px;
  }

  .ok_1 {
    color: #09bb07;
    font-size: 12px;
    margin: 15px 10px;
  }

  .ok_2 {
    color: #000000;
    font-size: 33px;
    font-weight: 500;
  }

  .ok_3 {
    color: #333333;
    font-size: 13px;
    text-align: left;
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
  }

  .ok_title {
    color: #333333;
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    text-align: left;
    font-weight: 500;
    font-size: 17px;
  }

  .order {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;

    & > li {
      display: flex;
      line-height: 25px;
      font-size: 13px;

      & > span:nth-child(1) {
        flex: 6;
        text-align: left;
      }

      & > span:nth-child(2) {
        flex: 18;
        text-align: right;
      }
    }

    .order_l {
      line-height: 25px;
      font-size: 13px;

      & > div:nth-child(1) {
        text-align: left;
      }

      & > div:nth-child(2) {
        color: #999999;
        font-size: 13px;
      }

      & > div:nth-child(3) {
        text-align: right;
      }
    }
  }

  .order_b {
    padding: 10px 0;

    & > li {
      display: flex;
      line-height: 25px;
      font-size: 13px;

      & > span:nth-child(1) {
        flex: 6;
        text-align: left;
      }

      & > span:nth-child(2) {
        flex: 18;
        text-align: right;
      }
    }
  }
}
</style>
